<template>
    <div class="w-full flex flex-col h-full">
        <Page
            ref="page"
            title="Rental Application"
            :loading="loading"
        >
            <template v-slot:header-addition>
                <div class="w-full bg-white flex flex-col flex-1 p-4 my-4">
                    <h2 class="font-bold text-center my-4 text-xl">Identity verification process</h2>
                </div>
                <div
                    v-if="loading"
                    class="ml-4"
                >
                    <h1 class="title">loading the next step, one moment please.</h1>
                </div>
            </template>
            <!-- Identity Verification Question -->
            <section
                v-if="transunion && transunion.Questions && transunion.Questions.length"
                class="my-4 ml-4"
            >
                <h2 class="hint mb-2 font-bold">
                    Please answer the below questions to verify your identity for the required background check.
                </h2>
                <span
                    v-if="error"
                    class="error"
                >{{ errorMessage }}</span
                >
                <div
                    v-if="isTransunionSet(transunion)"
                    class="tu-questions"
                >
                    <div
                        v-for="(question, index) in transunion.Questions"
                        :key="question.QuestionKeyName"
                        class="tu-question my-2"
                    >
                        <label>{{ `${index + 1}. ${question.QuestionDisplayName}` }}</label>
                        <div class="answers">
                            <div
                                v-for="(answer, idx) in question.Choices"
                                :key="answer.ChoiceKeyName"
                                class="answer"
                            >
                                <input
                                    :id="'q' + index + '_' + idx"
                                    type="radio"
                                    :value="answer"
                                    :name="'q' + index"
                                    class="mr-2 my-1"
                                    @change="selectAnswer(question.QuestionKeyName, answer.ChoiceKeyName)"
                                />
                                <label :for="'q' + index + '_' + idx">{{ answer.ChoiceDisplayName }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p class="info">One moment, loading your identity verification questions.</p>
                </div>
                <div class="btn-wrapper my-4">
                    <button
                        class="btn btn--primary"
                        @click="next"
                    >
                        {{ !saving ? 'submit' : 'saving' }}
                    </button>
                </div>
            </section>
        </Page>

        <div class="w-full bg-white flex flex-col flex-1 flex fixed bottom-0 p-4 mx-4">
            <h2 class="font-bold text-start my-4 text-base">
                Application status:
                <span class="text-purple-600">{{ application_status }}</span>
            </h2>
        </div>
    </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import NotifyMixin from '@/mixins/NotifyMixin';
import Page from '@/components/ui/Page';
import getEnvVariable from '@/utils/getEnvVariable';
import {
    SOMETHING_WENT_WRONG,
    IDENTITY_VERIFICATION_ERROR,
    EVALUATION_ERROR,
    SAVING_ID_VERIFICATION_RESULTS_ERROR,
    UPDATE_APPLICATION_ERROR,
    FETCH_DOCUMENT_INSTANCE_ERROR,
} from '@/utils/constants/leasing.js';

const baseURL = getEnvVariable('VUE_APP_INTEGRATIONS_SERVICE');

export default {
    name: 'TransunionVerification',
    components: {
        Page,
    },

    mixins: [NotifyMixin],
    props: {
        property: {
            type: Object,
            required: true,
        },

        application: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            currentApplication: null,
            answers: [],
            transunion: {},
            saving: false,
            error: false,
            errorMessage: '',
            loadQuestions: false,
            loading: true,
            application_status: 'Identity Verification',
            screeningFailed: false,
        };
    },

    computed: {
        ...mapGetters({
            getApplication: 'application/getApplication',
            getGuarantor: 'application/getGuarantor',
            getDocumentInstance: 'application/getDocumentInstance',
            getBackgroundScreening: 'application/getBackgroundScreening',
            currentProfile: 'auth/currentProfile',
        }),
    },

    async mounted() {
        if (this.getGuarantor?.application && this.getGuarantor?.application[0]?.document_package_instance_id) {
            await this.fetchDocumentInstance(this.getGuarantor?.application[0]?.document_package_instance_id);
        } else if (this.getDocumentInstance) {
            await this.fetchDocumentInstance(this.getDocumentInstance.document_package_instance_id);
        }
    },

    methods: {
        ...mapActions({
            setApplication: 'application/setApplication',
            setDocumentInstance: 'application/setDocumentInstance',
            clearState: 'application/clearState',
        }),

        goBack() {
            this.$router.push({ name: 'application_service.index' });
        },

        selectAnswer(question, answer) {
            const response = { QuestionKeyName: question, SelectedChoiceKeyName: answer };
            this.answers.map(answer => {
                if (answer.QuestionKeyName === response.QuestionKeyName) {
                    const index = this.answers.findIndex(answer => answer.QuestionKeyName === response.QuestionKeyName);
                    this.answers.splice(index, 1);
                }
            });
            this.answers.push(response);
        },

        restart() {
            this.clearState();
            this.currentAttempt = 1;
            this.$router.push({ name: 'application_service.index' });
        },

        next() {
            this.loading = true;
            this.saving = true;
            if (this.answers.length < this.transunion.Questions?.length) {
                this.error = true;
                this.errorMessage = 'We can not process your application until all the questions are answered.';
                this.loading = false;
                this.saving = false;
                setTimeout(() => {
                    this.error = false;
                }, 5000);
            } else {
                setTimeout(() => {
                    this.runEvaluation();
                }, 2500);
            }
        },

        async postData(url = '', data = {}) {
            let response = {};
            response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data),
            });

            return response.json();
        },

        mapDate(currentDate = '', isScreening = false) {
            const date = moment(currentDate).format('L');
            const dateSplited = date.split('/');

            return isScreening
                ? `${dateSplited[2]}-${dateSplited[0]}-${dateSplited[1]}`
                : `${dateSplited[0]}${dateSplited[1]}${dateSplited[2]}`;
        },

        getPhoneNumber() {
            return this.getDocumentInstance.phone || this.getDocumentInstance.alt_phone || this.getDocumentInstance.work_phone;
        },

        /* Fetch document instance */
        fetchDocumentInstance(document_id) {
            this.loading = true;
            this.$leasingServiceDataProvider
                .getOne('document_instance', {
                    document_package_instance_id: document_id,
                    business_unit_id: this.currentProfile.communityId,
                    customer_id: this.currentProfile.customerId,
                })
                .then(res => {
                    this.setDocumentInstance({
                        ...res.payload,
                        document_package_instance_id: res.document_package_instance_id,
                    });

                    this.runIdentityVerification();
                })
                .catch(() => {
                    this.notifyError(FETCH_DOCUMENT_INSTANCE_ERROR);
                })
                .finally(() => (this.loading = false));
        },

        /* Identity verification */
        runIdentityVerification() {
            this.loading = true;
            this.getDocumentInstance &&
                this.postData(`${baseURL}/transunion/identity`, {
                    CustomParameters: [],
                    Applicant: {
                        FirstName: this.getGuarantor.first_name,
                        LastName: this.getGuarantor.last_name,
                        MiddleName: this.getGuarantor.middle_name,
                        Suffix: '',
                        BirthDate: this.mapDate(this.getDocumentInstance.birth_date, false),
                        Ssn: this.getDocumentInstance?.social_security_number?.replace(/-/g, ''),
                        Phone: this.getPhoneNumber()?.replace(/[^\d]/g, ''),
                        EmailAddress: this.getDocumentInstance.email,
                        IdentificationNumber: '',
                        IdentificationType: '',
                        IdentificationState: '',
                        Addresses: [
                            {
                                StreetAddress: this.getDocumentInstance.current_address,
                                City: this.getDocumentInstance.current_city,
                                State: this.getDocumentInstance.current_st,
                                PostalCode: this.getDocumentInstance.current_zip,
                                Country: 'USA',
                                AddressType: 'Current',
                            },
                        ],
                        CustomParameters: [],
                    },
                    DeviceSessionId: '',
                })
                    .then(response => {
                        let isStringError = typeof response.error === 'string' || response.error instanceof String;
                        if (isStringError) {
                            if (response.error === 'Internal Server Error') {
                                this.notifyError('INTEGRATIONS: Network error');
                                return;
                            }
                            this.error = true;
                            this.errorMessage = IDENTITY_VERIFICATION_ERROR;
                            this.loading = false;
                        }

                        if (response.data.Result === 'ApplicantQuestioned') {
                            this.transunion = { ...response.data, Questions: [] };
                            this.loading = false;
                            if (response.data.Questions.length) {
                                const isAuthMethod = response.data.Questions.find(question => question.QuestionKeyName === 'IDM_Choice');

                                if (isAuthMethod) {
                                    const response = {
                                        QuestionKeyName: 'IDM_Choice',
                                        SelectedChoiceKeyName: 'Security Questions',
                                    };
                                    this.verificationFailed = false;
                                    this.screeningFailed = false;
                                    this.answers.push(response);
                                    this.runEvaluation();
                                    return;
                                }
                            }
                        }
                        if (response.data.Result === 'Completed') {
                            this.error = false;
                            this.errorMessage = '';
                            this.setApplication({
                                ...this.getApplication,
                                ...this.getGuarantor,
                                application_process_status: 'Identity verification',
                            });
                            this.updateApplicationRecord(
                                'Identity verification',
                                'identity verification process - Completed',
                                'identity verification - Completed'
                            );
                        }
                        if (response.data.Result === 'Fail') {
                            this.transunion = response.data;
                            this.loading = false;
                            this.application_status = 'Contact Office';
                            this.setApplication({
                                ...this.getApplication,
                                ...this.getGuarantor,
                                application_process_status: 'Mitigation',
                            });
                            /* Call update application record to update status */
                            this.updateApplicationRecord(
                                'Mitigation',
                                'identity verification process - failed',
                                'identity verification - failed'
                            );
                        }
                        this.saveIdentityVerificationResults(response.data);
                    })
                    .catch(() => {
                        this.loading = false;
                        this.verificationFailed = true;
                        this.error = true;
                        this.errorMessage = SOMETHING_WENT_WRONG;
                        this.notifyError(IDENTITY_VERIFICATION_ERROR);
                    });
        },

        /* Run evaluation */
        runEvaluation() {
            this.loading = true;
            this.postData(`${baseURL}/transunion/identity`, {
                Answers: this.answers,
                ReferenceNumber: this.transunion.ReferenceNumber,
                CustomParameters: [],
                DeviceSessionId: '',
            })
                .then(res => {
                    let isStringError = typeof res.error === 'string' || res.error instanceof String;
                    if (isStringError) {
                        if (res.error === 'Internal Server Error') {
                            this.notifyError('INTEGRATIONS: Network error');
                            return;
                        }
                        this.error = true;
                        this.errorMessage = EVALUATION_ERROR;
                        this.loading = false;
                    }
                    this.answers = [];
                    this.transunion = res.data;

                    if (res.data.Result === 'ApplicantQuestioned') this.loading = false;
                    if (res.data.Result === 'Completed') {
                        this.loading = true;
                        this.error = false;
                        this.errorMessage = '';
                        this.setApplication({ ...this.getApplication, application_process_status: 'Identity verification' });
                        /* Call update application record to update status */
                        this.updateApplicationRecord(
                            'Identity verification',
                            'identity evaluation process - completed',
                            'identity evaluation - completed'
                        );
                    }
                    if (res.data.Result === 'Fail') {
                        this.loading = false;
                        this.setApplication({ ...this.getApplication, ...this.getGuarantor, application_process_status: 'Mitigation' });
                        /* Call update application record to update status */
                        this.updateApplicationRecord('Mitigation', 'identity evaluation process - failed', 'identity evaluation - failed');
                    }
                    this.saveIdentityVerificationResults(res.data, true);
                })
                .catch(() => {
                    this.saving = false;
                    this.loading = false;
                    this.error = true;
                    this.verificationFailed = true;
                    this.errorMessage = SOMETHING_WENT_WRONG;
                    this.notifyError(EVALUATION_ERROR);
                });
        },

        /* Save identity verification results */
        saveIdentityVerificationResults(data, isFromEvaluation = false) {
            this.$leasingServiceDataProvider
                .create('identity_verification', {
                    data: {
                        person_id: this.getGuarantor.person_id,
                        reference_number: data.ReferenceNumber,
                        transunion_application_number: data.ApplicationNumber,
                        target_group: data.TargetGroup,
                        status: data.VerificationStatus,
                        result: data.Result,
                        file_type: data.FileType,
                        key_messages: data.KeyMessages,
                        fraud_alert_messages: data.FraudAlertMessages.length ? data.FraudAlertMessages[0] : '',
                        ofac_screen: data.OfacScreen.length ? data.OfacScreen[0] : '',
                        potential_risk: data.PotentialRisk,
                        warning: data.VerificationDetails,
                    },
                })
                .then(() => {
                    if ((data.Result === 'Completed' && isFromEvaluation) || data.Result === 'Fail') {
                        this.$router.push({ name: 'application_service.confirmation' });
                    }
                })
                .catch(() => {
                    this.notifyError(SAVING_ID_VERIFICATION_RESULTS_ERROR);
                })
                .finally(() => (this.loading = false));
        },

        isTransunionSet(data) {
            return !isEmpty(data);
        },

        /* Update application record */
        updateApplicationRecord(process_status, event_description, event_name) {
            const new_application = { ...this.getGuarantor };
            const currentPerson = new_application.persons?.find(person => person.contact_id === this.currentProfile.contactId);
            const currentParty = new_application.parties?.find(party => party.contact_id === this.currentProfile.contactId);
            this.loading = true;
            this.$leasingServiceDataProvider
                .patch('application', {
                    application_id: this.getGuarantor.application?.application_id,
                    data: {
                        occupancy_id: this.currentProfile.occupancyId,
                        contact_id: this.getGuarantor.contact_id ? this.getGuarantor.contact_id : new_application.contact_id,
                        household_id: new_application.household_id,
                        document_url: this.getDocumentInstance.document_url,
                        business_unit_id: new_application.business_unit_id,
                        customer_id: new_application.customer_id,
                        submission_date: this.getDocumentInstance.updated_at,
                        application_process_status: process_status,
                        event_description: event_description,
                        event_name: event_name,
                        current_address: this.getDocumentInstance.current_address,
                        current_city: this.getDocumentInstance.current_city,
                        current_st: this.getDocumentInstance.current_st,
                        current_zip: this.getDocumentInstance.current_zip,
                        ssn: this.getDocumentInstance?.social_security_number?.replace(/-/g, ''),
                        employment_income: this.getDocumentInstance.current_employer ?? 0,
                        employment_status: this.getDocumentInstance.current_employer,
                        is_primary: currentParty?.is_primary,
                        person_role: currentPerson?.person_role,
                    },
                })
                .catch(() => {
                    this.notifyError(UPDATE_APPLICATION_ERROR);
                })
                .finally(() => (this.loading = false));
        },
    },
};
</script>

<style scoped>
.title {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 1rem;
}
.error {
    background: #ffe8e3;
    border: 1px solid #e0b4a9;
    padding: 0.5rem;
    font-size: 0.75rem;
    display: block;
    color: #931b00;
    border-radius: 4px;
    margin-top: 8px;
}
.info {
    padding-top: 4rem;
}
.containers {
    margin-left: 15px;
}
</style>
